var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-layout',{staticClass:"mlayout"},[_c('a-layout',[_c('a-layout-content',{staticClass:"pc indexLayoutContent",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"page mlist flex-col justify-start",staticStyle:{"height":"auto"}},[_c('top-a',{attrs:{"kind":``,"topbarhide":_vm.topbarhide,"tb":_vm.tb,"w750":_vm.w750,"topbarOpacity":_vm.topbarOpacity,"lag":_vm.lag}}),_c('div',{staticClass:"group_0_3 flex-col"},[_c('div',{staticClass:"g03 flex-col"},[_c('img',{staticClass:"image_20",staticStyle:{"width":"1760px","height":"1px","margin":"0 auto"},attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng43e13759fd61200dd2aec8f3f42b50d1271142b403a2181742c322bf702eb876"}}),_c('div',{staticClass:"g03in flex-col"},[_c('div',{staticClass:"gbar flex-col justify-between",staticStyle:{"margin-top":"220px"}},[_c('div',{staticClass:"gtitles flex-col"},[_c('span',{staticClass:"text_13",staticStyle:{"color":"rgba(50, 50, 50, 1)","font-size":"80px","height":"80px","line-height":"80px"}},[_c('img',{staticStyle:{"height":"80px"},attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/title21.png"}})])]),_c('div',{staticClass:"gdes flex-col"},[_c('div',{staticClass:"textline",staticStyle:{"margin-top":"21px","color":"rgba(50, 50, 50, 1)","font-size":"24px","line-height":"24px","width":"700px"}},[_vm._v(" 你也喜欢露营么？此栏目应该很适合你-这里精选各种好物推荐，"),_c('br'),_vm._v("一起来看看啊～ ")])]),_c('div',{staticClass:"selectbar"},[_c('div',{class:`sbitem ${_vm.hwkind == '0' ? 'sbselected' : ''}`,on:{"click":() => {
                          _vm.sbselect('0');
                        }}},[_vm._v(" 全部 ")]),_c('div',{class:`sbitem ${_vm.hwkind == '1' ? 'sbselected' : ''}`,on:{"click":() => {
                          _vm.sbselect('1');
                        }}},[_vm._v(" 运动 ")]),_c('div',{class:`sbitem ${_vm.hwkind == '2' ? 'sbselected' : ''}`,on:{"click":() => {
                          _vm.sbselect('2');
                        }}},[_vm._v(" 露营 ")]),_c('div',{class:`sbitem ${_vm.hwkind == '3' ? 'sbselected' : ''}`,on:{"click":() => {
                          _vm.sbselect('3');
                        }}},[_vm._v(" 娱乐 ")])])]),_c('div',{staticClass:"g03mlist"},[_vm._l((_vm.clist),function(item,index){return [_c('div',{key:index,class:`g03div`,on:{"click":() => {
                          _vm.godetall(item.url, item.pname, item.kind, item.id);
                        }}},[_c('div',{staticClass:"g03item",style:(`background-image: url(${
                          item.imgurl.indexOf('https://') == -1
                            ? _vm.baseUrl + item.imgurl
                            : item.imgurl
                        });`)}),_c('div',{staticClass:"g03info"},[_c('div',{staticClass:"g03title",domProps:{"innerHTML":_vm._s(item.pname)}}),_c('div',{staticClass:"memberpn flex-row"},[(item.photo)?_c('div',{staticClass:"photo",style:(`background-image:url(${item.photo});`)}):_c('div',{staticClass:"photo"}),_c('div',{staticClass:"memberpninfo flex-col"},[_c('div',{staticClass:"pname"},[_vm._v("ALL FIELDS"+_vm._s(item.name))]),_c('div',{staticClass:"paddtime",domProps:{"innerHTML":_vm._s(item.addtime)}})])])])])]})],2),_c('div',{staticClass:"pageselect"},[_c('a-pagination',{attrs:{"size":"small","total":_vm.ctotal,"page-size":_vm.cpageSize},on:{"change":_vm.PageonChange},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}})],1)]),_c('div',{staticClass:"backimg"})])]),_c('footer-a',{attrs:{"kind":``,"lag":_vm.lag}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }